<div class="list-contrat-container" *ngIf="!selectedContrat">
  <div class="flex-horizontal gap-16">
    <app-search-investisseur-autocomplete
      class="flex-1"
      (onInvestisseurIdSelected)="onInvestisseurIdSelected($event)"
      [isClientStatutOnly]="false"></app-search-investisseur-autocomplete>
    <app-search-enveloppe-produit-autocomplete
      (onEnveloppeSelect)="onEnveloppeIdSelected($event)"
      (onProduitSelect)="onProduitSelected($event)"
      [useSpinner]="false"
      class="flex-horizontal gap-16 flex-1 width-100pc"></app-search-enveloppe-produit-autocomplete>
  </div>
  <div class="table">
    <app-contrats-table
      [receivedData]="requestResult"
      (requestNewPage)="getAllCommandes()"
      (onRowClicked)="onContratClick($event)"
      [(paginationParams)]="paginationParams"
      [withLoadingOnPageChange]="true"></app-contrats-table>
  </div>
</div>
<div class="selected-contrat-container" *ngIf="selectedContrat">
  <div class="selected-contrat-header">
    <div class="body-2" *ngIf="selectedContrat">Contrat sélectionné :</div>

    <a *ngIf="!isModifierDisabled" (click)="onModifier()">Modifier</a>
    <div *ngIf="isModifierDisabled" [ouiTooltip]="toolTipText" [ouiTooltipPosition]="toolTipPosition">
      <a class="link-disabled">Modifier</a>
    </div>
  </div>
  <div class="selected-contrat-body">
    <oui-table>
      <table class="padding-16 with-border mingap">
        <tr>
          <td>{{ selectedInvestisseurFullName }}</td>
          <td *ngIf="selectedCoInvestisseurFullName">
            {{ selectedCoInvestisseurFullName }}
          </td>
          <td>
            <div class="flex-vertical">
              <div class="body-2 color-on-surface-emphasis">
                N° {{ selectedContrat.numeroContrat ?? 'non renseigné' }}
              </div>
              <div class="caption color-on-surface-medium">
                {{
                  selectedContrat.dateActe
                    ? 'le ' + (selectedContrat.dateActe | date : 'dd-MM-yyyy')
                    : 'date non renseignée'
                }}
              </div>
            </div>
          </td>
          <td>{{ selectedContrat.produit.enveloppeNavigation?.libelle }}</td>
          <td>
            <div class="flex-vertical">
              <div class="body-2 color-on-surface-emphasis">
                {{ selectedContrat.produit.nomTechniqueProduitBox ?? selectedContrat.produit.nom }}
              </div>
              <div class="caption color-on-surface-medium">
                {{ selectedContrat.produit.idPartenaireNavigation?.nom | uppercase }}
              </div>
            </div>
          </td>
          <td>
            <div *ngIf="selectedContrat?.prixVenteTotalTTC">
              {{ selectedContrat.prixVenteTotalTTC | currency : 'EUR' : '€' }}
            </div>
            <div *ngIf="!selectedContrat?.prixVenteTotalTTC">non renseigné</div>
          </td>
        </tr>
      </table>
    </oui-table>
    <div class="flex-horizontal flex-center width-100pc oui-elevation-01 rounded">
      <div class="flex-vertical width-100pc rounded padding-16 border-left-primary">
        <div class="flex-horizontal flex-center">
          <oui-icon color="primary">info</oui-icon>
          <div class="subtitle-2 color-on-surface-emphasis padding-8">
            Après cette étape vous ne pourrez plus changer le contrat sélectionné.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
